import SearchBox from '@/component/search-box/1.0.1/index.vue'
import Pagination from '@/component/pagination/index.vue'
import moment from 'moment'


//火车票改签订单列表
import consumer_t_od_trains_order_change_trainChangeOrderListCenter from '@/lib/data-service/haolv-default/consumer_t_od_trains_order_change_trainChangeOrderListCenter'
//火车票改签订单列表分类数量
import consumer_t_od_trains_order_change_trainChangeOrderCountByStatus from '@/lib/data-service/haolv-default/consumer_t_od_trains_order_change_trainChangeOrderCountByStatus'
//火车票改签订单列表下载
import consumer_t_od_trains_order_change_trainChangeOrderListCenterExport from '@/lib/data-service/haolv-default/consumer_t_od_trains_order_change_trainChangeOrderListCenterExport'
import consumer_orderNo_center_userAllowBookingDepts from '@/lib/data-service/haolv-default/consumer_orderNo_center_userAllowBookingDepts'

export default {
    data() {
        return {
            loading: false,
            orderStatusData: -1,
            searchForm: {
                changeEndTime: '',//申请改签结束时间
                changeNo: '',//改签单号
                changeOrderStatus: -1,//改签单状态
                changeStartTime: '',//申请改签开始时间
                currentPage: 1,   //当前页码
                deptIds: [], //部门集合
                deptIdList: [],
                keyword: '',//不明物体
                orderBy: '',//排序条件
                orderNo: '',   // 原订单号
                pageSize: 10,   //每页记录条数
                passengerName: '', // 改签乘客
            },
            applyTime: [], //时间选择组件
            deptList: [],
            totalCount: 0,   //总条数
            activeName: '-1',
            tabList: [
                // 改签单状态 -1=全部  0=改签申请中，1=待支付，2=改签中，3=改签成功，4=改签失败，5=取消改签
                {
                    name: "-1",
                    label: "全部",
                    value: 0
                },
                {
                    name: "1",
                    label: "待支付",
                    value: 0
                },
                {
                    name: "2",
                    label: "改签中",
                    value: 0
                },
                {
                    name: "3",
                    label: "改签成功",
                    value: 0
                },
                {
                    name: "4",
                    label: "改签失败",
                    value: 0
                },
                {
                    name: "5",
                    label: "取消改签",
                    value: 0
                },

            ],
            orderList: [],
            canDownload: true,
        }
    },
    components: {
        SearchBox,
        Pagination
    },
    created() {
    },
    mounted() {
    },
    activated() {
        this.getDeptList();
        this.getOrderList();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {
        applyTime: {
            handler (val) {
                if (val && val.length) {
                    this.searchForm.changeStartTime = moment(val[0]).format('YYYY-MM-DD 00:00:00');
                    this.searchForm.changeEndTime = moment(val[1]).format('YYYY-MM-DD 23:59:59');
                } else {
                    this.searchForm.changeStartTime = '';
                    this.searchForm.changeEndTime = '';
                }
            }
        },
    },
    computed: {},
    filters: {},
    methods: {
        changeDept() {
            let deptId = [];
            this.searchForm.deptIdList.forEach(value => {
                value.forEach(value1 => {
                    deptId.push(value1);
                })
            })
            this.searchForm.deptIds = deptId;
        },
        // 获取部门信息
        getDeptList() {
            consumer_orderNo_center_userAllowBookingDepts().then(res => {
                this.deptList = res.datas.deptList;
            })
        },
        //查询按钮
        onSearch() {
            this.searchForm.currentPage = 1;
            this.getOrderList();
        },
        //重置按钮
        onReset() {
            this.searchForm = {
                changeEndTime: '',//申请改签结束时间
                    changeNo: '',//改签单号
                    changeOrderStatus: -1,//改签单状态
                    changeStartTime: '',//申请改签开始时间
                    currentPage: 1,   //当前页码
                    deptIds: [], //部门集合
                    deptIdList: [],
                    keyword: '',//不明物体
                    orderBy: '',//排序条件
                    orderNo: '',   // 原订单号
                    pageSize: 10,   //每页记录条数
                    passengerName: '', // 改签乘客
            };
            //重置日期选择器
            this.applyTime = [];
            this.onSearch()
        },
        //请求接口查找列表
        getOrderList() {
            this.loading = true;

            this.searchForm.changeOrderStatus = parseInt(this.activeName);
            let deptId = [];
            this.searchForm.deptIdList.forEach(value => {
                value.forEach(value1 => {
                    deptId.push(value1);
                })
            })
            this.searchForm.deptIds = deptId;
            consumer_t_od_trains_order_change_trainChangeOrderListCenter(this.searchForm).then(res => {
                this.orderList = res.datas.list;
                this.totalCount = res.datas.totalCount
            }).catch(err => {
                this.orderList = [];
                this.totalCount = 0
            }).finally(() => {
                this.loading = false
            });

            this.postTrainOrder();
        },
        //获取订单各状态数量
        postTrainOrder(){
            //预处理订单个状态数量
            let dataForm = this.searchForm;
            dataForm.changeOrderStatus = -1;

            consumer_t_od_trains_order_change_trainChangeOrderCountByStatus(dataForm).then(res => {
                res.datas.trainChangeOrderCountVoList.forEach((item,index) => {
                    this.tabList.forEach((tabItem,tabIndex) => {
                        if (item.changeOrderStatus == tabItem.name) {
                            tabItem.value = item.orderCount;
                        }
                    })
                });
            })
        },
        //切换
        tabClick(data){
            this.orderStatusData = Number(data.name);
        },
        //下载
        downloadOrderExcel() {
            if (!this.canDownload) {
                return
            }
            this.canDownload = false;
            this.searchForm.changeOrderStatus = this.orderStatusData;
            consumer_t_od_trains_order_change_trainChangeOrderListCenterExport(this.searchForm).then(res => {
                let url = res.datas.url;
                if (!url && url === '') {
                    this.$message({
                        type: "warning",
                        message: '下载失败，请稍候再试'
                    });
                    return
                }
                window.open(url, "_blank");
            }).finally(() => {
                this.canDownload = true
            })
        },
        handleDetailClick(val) {
            this.$router.push({
                name: 'admin-order-center-train-change-details',
                query: {
                    orderNo: val.changeNo
                }
            })
        },
        getStatusToClass(val) {
            // 0=改签申请中、1=待补款、2=改签中、3=改签成功、4=改签失败、5=已取消
            if (val === 0 || val === 1 || val === 2) {
                return 'yellow-text'
            } else if (val === 3) {
                return 'green-text'
            } else {
                return 'red-text'
            }
        },
    }
}
